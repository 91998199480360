import _ from 'lodash';

export const SoftphoneActions = {
    SetLoading: 'SET_LOADING',
    SetConnectionState: 'SET_CONNECTION_STATE',
    SetUserAgent: 'SET_USER_AGENT',
    AddSession: 'ADD_SESSION',
    RemoveSession: 'REMOVE_SESSION',
    UpdateSessions: 'UPDATE_SESSIONS',
    AddRemoteStreams: 'ADD_REMOTE_STREAMS',
    SetDialInput: 'SET_DIAL_INPUT',
    SetDialoutHandle: 'SET_DIALOUT_HANDLE',
};

export const softphoneReducer = (state, action) => {
    switch (action.type) {
        case SoftphoneActions.SetLoading:
            return {
                ...state,
                loading: !!action.payload,
            };
        case SoftphoneActions.SetConnectionState:
            return {
                ...state,
                connectionState: action.payload,
            };
        case SoftphoneActions.SetUserAgent:
            return {
                ...state,
                userAgent: action.payload,
            };
        case SoftphoneActions.AddSession: {
            let sessions = [...state.sessions];
            let found = sessions.find(
                (session) => session.id === action.payload.id
            );
            if (!_.isNil(found)) {
                return state;
            }
            sessions.push(action.payload);
            return {
                ...state,
                sessions,
            };
        }
        case SoftphoneActions.RemoveSession: {
            let sessions = state.sessions;
            let found = sessions.find(
                (session) => session.id === action.payload.id
            );
            if (_.isNil(found)) {
                return state;
            }
            sessions = sessions.filter(
                (session) => session.id !== action.payload.id
            );
            return {
                ...state,
                sessions,
            };
        }
        case SoftphoneActions.AddRemoteStreams: {
            let remoteStreams = [...state.remoteStreams];
            // Clean up old remote streams before adding new
            remoteStreams = remoteStreams.filter((remoteStream) => {
                return remoteStream.active;
            });
            remoteStreams = remoteStreams.concat(action.payload);
            return {
                ...state,
                remoteStreams,
            };
        }
        case SoftphoneActions.UpdateSessions: {
            // Update sessions by setting a fresh copy
            let sessions = [...state.sessions];
            return {
                ...state,
                sessions,
            };
        }
        case SoftphoneActions.SetDialInput: {
            return {
                ...state,
                dialInput: action.payload,
            };
        }
        case SoftphoneActions.SetDialoutHandle: {
            return {
                ...state,
                dialoutHandle: action.payload,
            };
        }
        default:
            return state;
    }
};
