import { lazy } from 'react';
import { Outlet, redirect } from 'react-router';
import { pageLoader as TagCategorySettingsPageLoader } from './tag-category/loader';
import {
    CrmMaintenanceAccountPermissionSet,
    Restricted,
} from '../../../../features';

const AccountSettingsPage = lazy(() => import('./account-settings-page'));
const DefaultAccountSettingsPage = lazy(() =>
    import('./default-account-settings/default-account-settings-page')
);
const LogoSettingsPage = lazy(() =>
    import('./logo-settings/logo-settings-page')
);
const ExternalIdentifierTypePage = lazy(() =>
    import('./external-identifier-type/external-identifier-type-page')
);
const TagCategoryPage = lazy(() => import('./tag-category/tag-category-page'));
const AbsenceReasonPage = lazy(() =>
    import('./absence-reason/absence-reason-page')
);
const TemplateActionPage = lazy(() =>
    import('./template-action/template-action-page')
);
const PhonebookPage = lazy(() => import('./phonebook/phonebook-page'));
const PhoneNumberTypePage = lazy(() =>
    import('./phone-number-type/phone-number-type-page')
);
const SubscriptionPage = lazy(() => import('./subscription/subscription-page'));

const SMSTemplatePage = lazy(() => import('./sms-template/sms-template-page'));

const TagCategorySettingsPage = lazy(() =>
    import('./tag-category/tag-category-settings-page')
);
const TagPage = lazy(() => import('./tag/tag-page'));

const DocumentsPage = lazy(() => import('./documents/documents-page'));

export const AccountSettingsRoutes = {
    path: 'account',
    element: (
        <Restricted to={CrmMaintenanceAccountPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    handle: {
        crumb: (t) => t('93aae2968df995f78e23eefab4b48ff2', 'Account settings'),
        documentTitleParent: true,
    },
    children: [
        {
            path: '',
            element: <AccountSettingsPage />,
            children: [
                {
                    index: true,
                    loader: () => redirect('default-account-settings'),
                },
                {
                    path: 'default-account-settings',
                    element: <DefaultAccountSettingsPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '69b3fd78c5d4c0a1828f87bbfc20cd30',
                                'Default account settings'
                            ),
                    },
                },
                {
                    path: 'account-logo',
                    element: <LogoSettingsPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'ed7d5d3471a3d8a3607a57e96829899b',
                                'Account logo'
                            ),
                    },
                },
                {
                    path: 'external-identifier-type',
                    element: <ExternalIdentifierTypePage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'c4f7a8358c584a68027d273a1ce4e04a',
                                'External identifier type'
                            ),
                    },
                },
                {
                    path: 'tag-category',
                    element: <TagCategoryPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'e22cbb5177c24c788f7e5bcd54b5ed79',
                                'Tag category'
                            ),
                    },
                },
                {
                    path: 'absence-reason',
                    element: <AbsenceReasonPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '784e966dd7845db860270ab08dacb34b',
                                'Absence reason'
                            ),
                    },
                },
                {
                    path: 'template-action',
                    element: <TemplateActionPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '519c26bea0c3b392829a029b13359df4',
                                'Action template'
                            ),
                    },
                },
                {
                    path: 'phonebook',
                    element: <PhonebookPage />,
                    handle: {
                        crumb: (t) =>
                            t('f0249d1970d98e6214abce6cf5a49dff', 'Phonebook'),
                    },
                },
                {
                    path: 'phone-number-type',
                    element: <PhoneNumberTypePage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'e1ef131918144ec7adeb7ed253dec2ea',
                                'Phone number type'
                            ),
                    },
                },
                {
                    path: 'subscription',
                    element: <SubscriptionPage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                'b48b13e73a6ac2a86dc54425dd24d9ff',
                                'Subscription'
                            ),
                    },
                },
                {
                    path: 'sms-template',
                    element: <SMSTemplatePage />,
                    handle: {
                        crumb: (t) =>
                            t(
                                '370c8ba46bfa6deaeb34b33526112089',
                                'SMS template'
                            ),
                    },
                },
                {
                    path: 'documents',
                    element: <DocumentsPage />,
                    handle: {
                        handle: {
                            crumb: (t) =>
                                t(
                                    '21f64da1e5792c8295b964d159a14491',
                                    'Documents'
                                ),
                        },
                    },
                },
            ],
        },
        {
            path: 'tag-category',
            handle: {
                crumb: (t) =>
                    t('e22cbb5177c24c788f7e5bcd54b5ed79', 'Tag category'),
            },
            children: [
                {
                    path: ':tagCategoryId',
                    element: <TagCategorySettingsPage />,
                    loader: TagCategorySettingsPageLoader,
                    handle: {
                        crumb: (_t, data) => data.name,
                    },
                    children: [
                        {
                            index: true,
                            loader: () => redirect('tag'),
                        },
                        {
                            path: 'tag',
                            element: <TagPage />,
                            handle: {
                                crumb: (t) =>
                                    t(
                                        'e4d23e841d8e8804190027bce3180fa5',
                                        'Tag'
                                    ),
                            },
                        },
                    ],
                },
            ],
        },
    ],
};
