import { useTranslation } from 'react-i18next';
import { EdsPage, UmoSearchFollowUpAlarmTable } from '../../../components';
import { useRef } from 'react';
import { useAuth, useNavigate } from '../../../features';
import _ from 'lodash';

function FollowUpPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { attributes } = useAuth();
    const tableRef = useRef();

    const getAccountIdFromRow = (rowId) => {
        const rowData = tableRef?.current?.getRowData(rowId);
        const account = attributes?.accounts?.find(
            (item) => item?.name === rowData?.account
        );
        return account?.id;
    };
    return (
        <EdsPage title={t('ff853246d679191f35c7c966204d0ec9', 'Follow-up')}>
            <UmoSearchFollowUpAlarmTable
                ref={tableRef}
                title={null}
                showActionButtonsAsOverflowMenu={true}
                onNavigateToClick={(row) => {
                    const accountId = getAccountIdFromRow(row?.id);
                    if (!_.isNil(accountId)) {
                        navigate(`/account/${accountId}/crm/alarm/${row.id}`);
                    }
                }}
                navigateToLabel={t(
                    'a0b6a9ed8f1fe9e76f703db8ec9f3571',
                    'Open in CRM'
                )}
            />
        </EdsPage>
    );
}

export default FollowUpPage;
