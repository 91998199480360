import './umo-control-device.scss';
import { useTranslation } from 'react-i18next';
import {
    Close,
    Devices,
    VolumeDownAlt,
    VolumeUpAlt,
} from '@carbon/icons-react';
import { UmoControlPanelComponent } from '../umo-control-panel-component/umo-control-panel-component';
import {
    ContentSwitcher,
    OverflowMenu,
    OverflowMenuItem,
    Switch,
    Toggle,
} from '@carbon/react';
import { useEffect, useState } from 'react';
import {
    AlarmControlIconButton,
    AlarmControlIconButtonWrapper,
    UmoAlarmControlIconButtonType,
} from '../umo-alarm-control-icon-button/umo-alarm-control-icon-button';
import {
    getDevice,
    postAlarmCloseDevice,
    postAlarmDeviceDoorOpen,
    postAlarmDeviceDuplex,
    postAlarmDeviceVolume,
    useAlarms,
    useNotifications,
} from '../../../../features';
import _ from 'lodash';

const DeviceDuplexTypes = Object.freeze({
    FullDuplex: 'full_duplex',
    OperatorSpeak: 'operator_speak',
    OperatorListen: 'operator_listen',
});

const VolumeDirectionTypes = Object.freeze({
    Up: 'up',
    Down: 'down',
});

const DoorOpenTypes = Object.freeze({
    DoorOne: '1',
    DoorTwo: '2',
});

export function UmoAlarmControlDevice() {
    const { t } = useTranslation();
    const { operableAlarm } = useAlarms();
    const { showSuccess, showError } = useNotifications();

    const [deviceCode, setDeviceCode] = useState(null);
    const [deviceType, setDeviceType] = useState(null);
    const [deviceDuplex, setDeviceDuplex] = useState(
        DeviceDuplexTypes.FullDuplex
    );
    const [isDeviceClosed, setIsDeviceClosed] = useState(
        _.isNil(operableAlarm?.id) ||
            _.isNil(operableAlarm?.data?.session_handle) ||
            !!operableAlarm?.device_closed
    );

    useEffect(
        () => {
            const deviceNowClosed =
                _.isNil(operableAlarm?.id) ||
                _.isNil(operableAlarm?.data?.session_handle) ||
                !!operableAlarm?.device_closed;
            // Set device duplex to full duplex on opening new alarm
            if (isDeviceClosed && !deviceNowClosed) {
                setDeviceDuplex(DeviceDuplexTypes.FullDuplex);
            }
            setIsDeviceClosed(deviceNowClosed);

            const updateDevice = async (deviceId) => {
                const device = await getDevice(deviceId);
                // Use empty string to show unknown device/type
                setDeviceCode(device?.deviceCode ?? '');
                setDeviceType(device?.deviceType?.name ?? '');
            };

            if (!_.isNil(operableAlarm?.data)) {
                const deviceId = operableAlarm?.data?.device_id;
                if (!_.isNil(deviceId) && deviceId !== '0') {
                    updateDevice(deviceId);
                } else {
                    // Use empty string to show unknown device/type
                    setDeviceCode('');
                    setDeviceType('');
                }
            } else {
                setDeviceCode(null);
                setDeviceType(null);
            }
        },
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            setIsDeviceClosed,
            setDeviceDuplex,
            setDeviceCode,
            setDeviceType,
            operableAlarm,
        ]
    );

    const changeDeviceDuplex = async (duplex) => {
        try {
            await postAlarmDeviceDuplex(operableAlarm?.id, duplex);
            setDeviceDuplex(duplex);
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const changeDeviceVolume = async (volumeDirection) => {
        try {
            await postAlarmDeviceVolume(operableAlarm?.id, volumeDirection);
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const deviceDoorOpen = async (doorId) => {
        try {
            await postAlarmDeviceDoorOpen(operableAlarm?.id, doorId);
            showSuccess({
                title: t('3a50c5e41a1c3eee6dcddca9e04992e0', 'Opened'),
                content: t(
                    'e8c053aa128b24c09f7dc555b01246e9',
                    'Door {{doorId}} is opened',
                    { doorId }
                ),
            });
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    const closeDevice = async () => {
        try {
            await postAlarmCloseDevice(operableAlarm?.id);
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <UmoControlPanelComponent
            icon={Devices}
            title={t('39aa5f03f2e211ee6e6088099d56a9c2', 'Device controls')}
            className="alarm-control-device"
        >
            <div className="device-details">
                {(!_.isNil(deviceCode) || !_.isNil(deviceType)) && (
                    <div className="detail-header">
                        <div className="detail-header-title">
                            {!_.isEmpty(deviceCode)
                                ? deviceCode
                                : t(
                                      '9caa2faa66022e8e1905eb7e87ddf68f',
                                      'Unknown device'
                                  )}
                        </div>
                        <div className="detail-header-subtitle">
                            {!_.isEmpty(deviceType)
                                ? deviceType
                                : t(
                                      '461b6b4587ba4f0eaa1eaba487d5808a',
                                      'Unknown type'
                                  )}
                        </div>
                    </div>
                )}

                <div className="duplex-toggle">
                    <Toggle
                        labelA={t(
                            'be9289739753c131848f2f07efd33621',
                            'Half duplex'
                        )}
                        labelB={t(
                            'b31bf13d37c2e0c381cdee7a079ffaae',
                            'Full duplex'
                        )}
                        toggled={deviceDuplex === DeviceDuplexTypes.FullDuplex}
                        size="sm"
                        id="duplex-toggle"
                        disabled={isDeviceClosed}
                        onClick={() => {
                            if (deviceDuplex === DeviceDuplexTypes.FullDuplex) {
                                changeDeviceDuplex(
                                    DeviceDuplexTypes.OperatorListen
                                );
                            } else {
                                changeDeviceDuplex(
                                    DeviceDuplexTypes.FullDuplex
                                );
                            }
                        }}
                    />

                    {deviceDuplex !== DeviceDuplexTypes.FullDuplex && (
                        <div className="half-duplex-toggle">
                            <ContentSwitcher
                                size="sm"
                                selectedIndex={
                                    deviceDuplex ===
                                    DeviceDuplexTypes.OperatorListen
                                        ? 0
                                        : 1
                                }
                                onChange={(event) => {
                                    if (event?.name === 'listening') {
                                        changeDeviceDuplex(
                                            DeviceDuplexTypes.OperatorListen
                                        );
                                    } else if (event?.name === 'speaking') {
                                        changeDeviceDuplex(
                                            DeviceDuplexTypes.OperatorSpeak
                                        );
                                    }
                                }}
                            >
                                <Switch
                                    name="listening"
                                    disabled={isDeviceClosed}
                                    text={t(
                                        'a6e71e02cd9c6565c2ad2fbaf135bf64',
                                        'Listening'
                                    )}
                                />
                                <Switch
                                    name="speaking"
                                    disabled={isDeviceClosed}
                                    text={t(
                                        'd1715efc5a67ac1c988152b8136e3dfa',
                                        'Speaking'
                                    )}
                                />
                            </ContentSwitcher>
                        </div>
                    )}
                </div>
            </div>
            <div className="device-controls">
                <AlarmControlIconButtonWrapper>
                    <AlarmControlIconButton
                        icon={VolumeUpAlt}
                        disabled={isDeviceClosed}
                        label={t(
                            '94cdaa0470753e5097f5ebb729b2387d',
                            'Volume up'
                        )}
                        onClick={() =>
                            changeDeviceVolume(VolumeDirectionTypes.Up)
                        }
                    />
                    <AlarmControlIconButton
                        icon={VolumeDownAlt}
                        disabled={isDeviceClosed}
                        label={t(
                            '4f27abb939d2941b7554aee40f2441b4',
                            'Volume down'
                        )}
                        onClick={() =>
                            changeDeviceVolume(VolumeDirectionTypes.Down)
                        }
                    />
                    <AlarmControlIconButton
                        icon={Close}
                        disabled={isDeviceClosed}
                        type={UmoAlarmControlIconButtonType.Danger}
                        label={t(
                            'eecccd7bc30352690b2dbc58a24799a8',
                            'Close device'
                        )}
                        onClick={closeDevice}
                    />

                    <OverflowMenu
                        className="alarm-control-icon-button"
                        menuOptionsClass="alarm-control-icon-menu"
                        size="sm"
                        aria-label={t(
                            '7e5bf7ef2c5eafb8693d78c5a796fac1',
                            'More device controls'
                        )}
                        disabled={isDeviceClosed}
                    >
                        <OverflowMenuItem
                            itemText={t(
                                'aa265a5006c3df8169b453f659a80bff',
                                'Door open'
                            )}
                            onClick={() =>
                                deviceDoorOpen(DoorOpenTypes.DoorOne)
                            }
                        />
                        <OverflowMenuItem
                            itemText={t(
                                'af380012b78ba0b5b143b16d7ee77919',
                                'Door open 2'
                            )}
                            onClick={() =>
                                deviceDoorOpen(DoorOpenTypes.DoorTwo)
                            }
                        />
                        {/* <OverflowMenuItem itemText="Key safe open" /> */}
                        {/* <OverflowMenuItem itemText="BS Inelelink override speech" /> */}
                    </OverflowMenu>
                </AlarmControlIconButtonWrapper>
            </div>
        </UmoControlPanelComponent>
    );
}
