import { Help, Logout, PhoneIp } from '@carbon/icons-react';
import {
    Content,
    Header,
    HeaderGlobalAction,
    HeaderGlobalBar,
    HeaderMenuButton,
    HeaderMenuItem,
    HeaderName,
    HeaderNavigation,
} from '@carbon/react';
import enovationLogo from '../../../assets/img/enovation.png';
import umoLogo from '../../../assets/img/umo.png';
import './eds-ui-shell.scss';
import {
    EdsNotificationsWrapper,
    EdsModalWrapper,
    EdsOrganizationSwitcher,
    EdsSideNav,
    EdsLanguageSwitcher,
    EdsBadgeKind,
    EdsBadge,
} from '../';
import { useEffect, useState } from 'react';
import {
    useAuth,
    useChannels,
    useNotifications,
    useNavigate,
    useAlarms,
    useSoftphone,
    SoftphoneConnectionState,
} from '../../../features';
import { NavLink, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UmoAlarmControlPanel, UmoNavLink } from '../../umo';
import _ from 'lodash';

export function EdsUiShell({ children }) {
    const { connectionState, dialInput } = useSoftphone();
    const [dialoutInputEmpty, setDialoutInputEmpty] = useState(
        _.isEmpty(dialInput)
    );
    const [isSideNavExpanded, setIsSideNavExpanded] = useState(true);
    const [isAlarmControlExpanded, setIsAlarmControlExpanded] = useState(true);
    const [showAlarmControlIndicator, setShowAlarmControlIndicator] = useState(
        connectionState !== SoftphoneConnectionState.Connected
    );
    const { logout, attributes, accountId, selectAccount } = useAuth();
    const { alarmQueueCount, activeAlarmQueueCount, operableAlarm } =
        useAlarms();
    const { openSocket } = useChannels();
    const navigate = useNavigate();
    const { showInfo } = useNotifications();
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen(1054); //based on pixels that carbon uses to show/hide the sidebar toggle. eq around 66rem
    const location = useLocation();

    useEffect(() => {
        openSocket();
    }, [openSocket]);

    useEffect(() => {
        toggleSideNavExpandedIfNeeded();
        // TODO UMO-631 Use useEffectEvent hook and remove linter suppression
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        setShowAlarmControlIndicator(
            connectionState !== SoftphoneConnectionState.Connected
        );
    }, [connectionState, setShowAlarmControlIndicator]);

    useEffect(() => {
        const inputWasEmpty = dialoutInputEmpty;
        setDialoutInputEmpty(_.isEmpty(dialInput));
        if (inputWasEmpty && !_.isEmpty(dialInput) && !isAlarmControlExpanded) {
            setIsAlarmControlExpanded(true);
        }
    }, [
        dialoutInputEmpty,
        dialInput,
        isAlarmControlExpanded,
        setIsAlarmControlExpanded,
    ]);

    const toggleSideNavExpanded = () => {
        setIsSideNavExpanded(!isSideNavExpanded);
    };

    const toggleSideNavExpandedIfNeeded = () => {
        //only when isSmallScreen
        if (isSmallScreen && isSideNavExpanded) {
            setIsSideNavExpanded(!isSideNavExpanded);
        }
    };

    const toggleAlarmControlExpanded = () => {
        setIsAlarmControlExpanded(!isAlarmControlExpanded);
    };

    useEffect(() => {
        setIsSideNavExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    const showCRMElements = () => {
        return location.pathname.startsWith('/account');
    };

    const getStyle = () => {
        let classes = ['ui-shell-container'];
        if (showCRMElements()) {
            classes.push('crm');
        }
        if (isSideNavExpanded) {
            classes.push('crm-side-nav');
        }
        if (location.pathname.startsWith('/alarm')) {
            classes.push('alarm');
        }
        if (isAlarmControlExpanded) {
            classes.push('alarm-controls');
        }

        return classes.join(' ');
    };

    return (
        <div className={getStyle()}>
            <Header
                aria-label={t('099fb995346f31c749f6e40db0f395e3', 'Header')}
                className="ui-shell-header"
            >
                {showCRMElements() && (
                    <HeaderMenuButton
                        isActive={isSideNavExpanded}
                        aria-label={t(
                            '0ce85727152c0c7a54f5001e39a1b339',
                            'Header navigation'
                        )}
                        onClick={toggleSideNavExpanded}
                    ></HeaderMenuButton>
                )}
                <HeaderName as={UmoNavLink} to="/" prefix="">
                    <img
                        src={enovationLogo}
                        id="enovation-logo"
                        alt={t(
                            'd64aba1bf6458856a5c3a8e80f19dd86',
                            'Enovation logo'
                        )}
                    />
                    <img
                        src={umoLogo}
                        id="platform-logo"
                        alt={t('573f227afd00058e9cfd6e501c8cc29e', 'UMO logo')}
                    />
                </HeaderName>
                <HeaderNavigation
                    aria-label={t(
                        '0ce85727152c0c7a54f5001e39a1b339',
                        'Header navigation'
                    )}
                >
                    <HeaderMenuItem
                        isActive={location.pathname.startsWith('/alarm/queue')}
                        as={UmoNavLink}
                        to="/alarm/queue"
                    >
                        {t('3517d17f97589f6ee011e4d64ba4ec90', 'Alarm queue')}
                        <div
                            style={{
                                float: 'right',
                                marginLeft: '0.5rem',
                            }}
                        >
                            ({alarmQueueCount + activeAlarmQueueCount})
                        </div>
                    </HeaderMenuItem>

                    {operableAlarm && (
                        <HeaderMenuItem
                            isActive={location.pathname.startsWith(
                                '/alarm/details'
                            )}
                            as={UmoNavLink}
                            to={`alarm/details/${operableAlarm?.id}`}
                        >
                            {t(
                                'f4585f2f2ec5ef2f1d7eff044b33a330',
                                'Active alarm'
                            )}
                        </HeaderMenuItem>
                    )}

                    <HeaderMenuItem
                        isActive={location.pathname.startsWith(
                            '/alarm/follow-up'
                        )}
                        as={UmoNavLink}
                        to="/alarm/follow-up"
                    >
                        {t('ff853246d679191f35c7c966204d0ec9', 'Follow-up')}
                    </HeaderMenuItem>

                    <HeaderMenuItem
                        isActive={location.pathname.startsWith(
                            '/alarm/history'
                        )}
                        as={UmoNavLink}
                        to="/alarm/history"
                    >
                        {t('3cd15f8f2940aff879df34df4e5c2cd1', 'History')}
                    </HeaderMenuItem>

                    <HeaderMenuItem
                        isActive={location.pathname.startsWith('/account')}
                        as={UmoNavLink}
                        to="/account"
                    >
                        {t('95ffdefa64d418cd17ee38c3f467917e', 'CRM')}
                    </HeaderMenuItem>
                </HeaderNavigation>

                <HeaderGlobalBar>
                    {showCRMElements() && (
                        <EdsOrganizationSwitcher
                            organizations={attributes?.accounts || []}
                            initialSelectedItem={
                                attributes?.accounts &&
                                attributes?.accounts?.find(
                                    (item) => `${item.id}` === accountId
                                )
                            }
                            onChange={(e) => {
                                if (e?.selectedItem?.id) {
                                    selectAccount(e.selectedItem.id);
                                    showInfo({
                                        title: t(
                                            '4024503a04abfd12238b814aac13903e',
                                            'Account switched'
                                        ),
                                        content: t(
                                            '58befd7b18a12147bc7fa84a6a9ba7f4',
                                            'Account has been switched to {{accountName}}',
                                            { accountName: e.selectedItem.name }
                                        ),
                                        ttl: 2000,
                                    });
                                    navigate(`/account/${e.selectedItem.id}`);
                                }
                            }}
                        />
                    )}

                    <EdsLanguageSwitcher
                        onChangeCallback={(languageItem) => {
                            showInfo({
                                title: t(
                                    'e1b6ef893ec76b111ef72c6fc57f0c42',
                                    'Language switched'
                                ),
                                content: t(
                                    'ea82d9b45fffc3f0fde6bb577431f64e',
                                    'Language has been switched to {{languageName}}',
                                    { languageName: languageItem.name }
                                ),
                                ttl: 2000,
                            });
                        }}
                    />

                    <HeaderGlobalAction
                        isActive={isAlarmControlExpanded}
                        aria-label={t(
                            '8bf4d5ed6ed433d05f85df58755669bb',
                            'Alarm control'
                        )}
                        onClick={toggleAlarmControlExpanded}
                    >
                        <EdsBadge
                            kind={EdsBadgeKind.HeaderIndicator}
                            danger={true}
                            count={showAlarmControlIndicator ? 1 : 0}
                        >
                            <PhoneIp size={20} />
                        </EdsBadge>
                    </HeaderGlobalAction>

                    <HeaderGlobalAction
                        aria-label={t(
                            '657f8b8da628ef83cf69101b6817150a',
                            'Help'
                        )}
                        onClick={() => {}}
                        tooltipAlignment="end"
                        as={NavLink}
                        to="https://enovationgroup.com/support/"
                        target="_blank"
                    >
                        <Help size={20} />
                    </HeaderGlobalAction>
                    {/* <HeaderGlobalAction
                        aria-label={t(
                            '2e5d8aa3dfa8ef34ca5131d20f9dad51',
                            'Settings'
                        )}
                        onClick={() => {}}
                        tooltipAlignment="end"
                    >
                        <Settings size={20} />
                    </HeaderGlobalAction> */}
                    <HeaderGlobalAction
                        aria-label={t(
                            '4236a440a662cc8253d7536e5aa17942',
                            'Logout'
                        )}
                        onClick={() => {
                            logout();
                        }}
                        tooltipAlignment="end"
                    >
                        <Logout size={20} />
                    </HeaderGlobalAction>
                </HeaderGlobalBar>

                <UmoAlarmControlPanel expanded={isAlarmControlExpanded} />
            </Header>

            {showCRMElements() && (
                <EdsSideNav
                    expanded={isSideNavExpanded}
                    onOverlayClick={toggleSideNavExpandedIfNeeded}
                    version={window.umo_version}
                    isRail={false}
                ></EdsSideNav>
            )}

            <div className="content-wrap">
                <Content>{children}</Content>
                <div className="content-footer"></div>
            </div>

            <EdsNotificationsWrapper />
            <EdsModalWrapper />
        </div>
    );
}

export default function useIsSmallScreen(threshold) {
    const [isSmallScreen, setIsSmallScreen] = useState(
        window.innerWidth <= threshold
    );

    useEffect(() => {
        const onResize = () => {
            setIsSmallScreen(window.innerWidth <= threshold);
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [threshold]);

    return isSmallScreen;
}
