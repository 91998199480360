import {
    deleteRequest,
    getRequest,
    multipartPostRequest,
    putRequest,
} from '../api';

export const getTenantSettings = async () => {
    return getRequest('/maintenance/tenant/settings');
};

export const putTenantSettings = async (tenantSettings) => {
    return putRequest('/maintenance/tenant/settings', tenantSettings);
};

export const getTenantGoogleMapsSettings = async () => {
    return getRequest('/maintenance/tenant/settings/googleMaps');
};

export const putTenantGoogleMapsSettings = async (tenantGoogleMapsSettings) => {
    return putRequest(
        '/maintenance/tenant/settings/googleMaps',
        tenantGoogleMapsSettings
    );
};

export const getTenantLogoUrl = () => {
    return '/api/maintenance/tenant/settings/logo';
};

export const postTenantLogo = async (file, cbProgress, abortController) => {
    let form = new FormData();
    form.append('file', file);
    const customHeaders = {};

    return multipartPostRequest(
        '/maintenance/tenant/settings/logo',
        form,
        customHeaders,
        cbProgress,
        abortController
    );
};

export const deleteTenantLogo = async () => {
    return deleteRequest('/maintenance/tenant/settings/logo');
};
