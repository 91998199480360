import {
    createContext,
    memo,
    useCallback,
    useContext,
    useReducer,
} from 'react';
import {
    getLogger,
    postAlarmDialout,
    postAlarmDtmf,
    postAlarmEndDialout,
    useAccountId,
    useAlarms,
    useAuth,
    useNotifications,
} from './../../features';
import { useTranslation } from 'react-i18next';
import { SoftphoneActions, softphoneReducer } from './reducer';
import JsSip from 'jssip';
import _ from 'lodash';
import { SoftphoneConnectionState } from './softphone-connection-state';

const logger = getLogger('SoftphoneProvider');

const SoftphoneContext = createContext({
    userAgent: null,
    sessions: [],
    remoteStreams: [],
    dialInput: '',
    dialoutHandle: null,
    loading: false,
    connectionState: SoftphoneConnectionState.NoCredentials,
    registerSip: () => null,
    holdCall: () => null,
    unholdCall: () => null,
    toggleMute: () => null,
    sendDTMF: () => null,
    getActiveSession: () => null,
    dialpadInput: () => null,
    setDialInput: () => null,
    startDialout: () => null,
    endDialout: () => null,
});

export const SoftphoneProvider = memo(({ children }) => {
    const { attributes } = useAuth();
    const { showError } = useNotifications();
    const accountId = useAccountId();
    const { operableAlarm } = useAlarms();
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(softphoneReducer, {
        userAgent: null,
        sessions: [],
        remoteStreams: [],
        dialInput: '',
        dialoutHandle: null,
        loading: false,
        connectionState: SoftphoneConnectionState.NoCredentials,
    });

    const registerSip = useCallback(() => {
        if (state.loading) {
            logger.log('[registerSip] Already loading');
            return;
        }

        if (state.userAgent) {
            logger.log(
                '[registerSip] Already has user agent: connected:',
                state.userAgent.isConnected(),
                'registered:',
                state.userAgent.isRegistered()
            );
            return;
        }

        let credentials = attributes?.sip_credentials;
        if (_.isNil(credentials) || _.isEmpty(credentials)) {
            logger.log(
                '[registerSip] No SIP credentials available:',
                credentials
            );
            return;
        }

        if (
            !credentials.url ||
            !credentials.contact_uri ||
            !credentials.auth_username ||
            !credentials.username ||
            !credentials.password
        ) {
            logger.error('[registerSip] Invalid SIP credentials:', credentials);
            showErrorNotification(
                t('e7f2048ff60c0fe4dcecdacc010b3bcb', 'Invalid SIP credentials')
            );
            return;
        }

        let url = credentials.url;
        let contactUri = credentials.contact_uri;
        let authUsername = credentials.auth_username;
        let username = credentials.username;
        let password = credentials.password;

        dispatch({
            type: SoftphoneActions.SetLoading,
            payload: true,
        });

        logger.log(
            '[registerSip] Registering SIP: url:',
            url,
            'user:',
            username,
            'authUser:',
            authUsername
        );

        let socket = new JsSip.WebSocketInterface(url);
        let configuration = {
            sockets: [socket],
            authorization_user: authUsername,
            uri: username,
            contact_uri: contactUri,
            password: password,
            connection_recovery_max_interval: 10,
            connection_recovery_min_interval: 1,
            no_answer_timeout: 30,
            // user_agent: 'UMOcx',
            // extra_headers: [ "Foo: ABC", "Bar: XYZ" ]
        };

        let userAgent = new JsSip.UA(configuration);

        setUserAgentListeners(userAgent);

        updateConnectionState(userAgent);

        userAgent.start();

        dispatch({
            type: SoftphoneActions.SetUserAgent,
            payload: userAgent,
        });

        dispatch({
            type: SoftphoneActions.SetLoading,
            payload: false,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, attributes]);

    const holdCall = useCallback(
        (id) => {
            // TODO Use Operator Service API call when available
            logger.log('[holdCall] Hold call: id:', id);
            let session;
            if (!_.isNil(id)) {
                session = getSession(id);
            } else {
                session = getActiveSession();
            }
            if (!_.isNil(session)) {
                session.hold();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    const unholdCall = useCallback(
        (id) => {
            // TODO Use Operator Service API call when available
            logger.log('[unholdCall] Unhold call: id:', id);
            let session;
            if (!_.isNil(id)) {
                session = getSession(id);
            } else {
                session = getActiveSession();
            }
            if (!_.isNil(session)) {
                session.unhold();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    const toggleMute = useCallback(
        () => {
            logger.log('[toggleMute]');
            const session = getActiveSession();
            if (!_.isNil(session)) {
                const isMuted = session.isMuted();
                const muteData = { audio: true, video: false };
                if (isMuted?.audio) {
                    session.unmute(muteData);
                } else {
                    session.mute(muteData);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    const getActiveSession = useCallback(() => {
        return state.sessions.find(
            (session) => session.isEstablished() || session.isInProgress()
        );
    }, [state]);

    const sendDTMF = useCallback(
        (input) => {
            logger.log('[sendDTMF] Sending DTMF:', input);
            const account = attributes?.accounts?.find(
                (item) => `${item.id}` === `${accountId}`
            );
            postAlarmDtmf(
                account?.umoAccountIdentifier ?? '',
                operableAlarm?.id ?? 0,
                state.dialoutHandle,
                input
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    const dialpadInput = useCallback(
        (input) => {
            logger.log('[dialpadInput] Input:', input);

            let session = getActiveSession();
            if (!_.isNil(session)) {
                sendDTMF(input);
            }

            dispatch({
                type: SoftphoneActions.SetDialInput,
                payload: state.dialInput + `${input}`,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    const setDialInput = useCallback(
        (value) => {
            logger.log('[setDialInput] value:', value);
            dispatch({
                type: SoftphoneActions.SetDialInput,
                payload: value,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state]
    );

    const startDialout = useCallback(
        async () => {
            logger.log(
                '[startDialout] Dialing:',
                state.dialInput,
                'accountId:',
                accountId,
                'alarmId:',
                operableAlarm?.id
            );

            const account = attributes?.accounts?.find(
                (item) => `${item.id}` === `${accountId}`
            );

            try {
                const response = await postAlarmDialout(
                    account?.umoAccountIdentifier ?? '',
                    operableAlarm?.id ?? 0,
                    state.dialInput?.trim()
                );

                dispatch({
                    type: SoftphoneActions.SetDialoutHandle,
                    payload: response?.dialoutHandle,
                });

                dispatch({
                    type: SoftphoneActions.SetDialInput,
                    payload: '',
                });
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state, accountId, operableAlarm, attributes]
    );

    const endDialout = useCallback(
        async () => {
            logger.log(
                '[endDialout] Ending dialout:',
                state.dialoutHandle,
                'accountId:',
                accountId,
                'alarmId:',
                operableAlarm?.id
            );

            const account = attributes?.accounts?.find(
                (item) => `${item.id}` === `${accountId}`
            );

            try {
                await postAlarmEndDialout(
                    account?.umoAccountIdentifier ?? '',
                    operableAlarm?.id ?? 0,
                    state.dialoutHandle
                );

                dispatch({
                    type: SoftphoneActions.SetDialoutHandle,
                    payload: null,
                });
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state, accountId, operableAlarm, attributes]
    );

    const answerCall = (session) => {
        logger.log('[answerCall] Answering incoming call:', session);

        setSessionListeners(session);

        let options = {
            // extraHeaders: ['X-Foo: foo', 'X-Bar: bar'],
            mediaConstraints: { audio: true, video: false },
            sessionTimersExpires: 120,
        };

        try {
            session.answer(options);
            dispatch({
                type: SoftphoneActions.AddSession,
                payload: session,
            });
        } catch (err) {
            logger.error('[answerCall] Unable to answer call:', err);
            showErrorNotification(
                t(
                    '136467b641710f01e7a54f629a4d505e',
                    'Unable to answer softphone call'
                )
            );
        }
    };

    const setUserAgentListeners = (userAgent) => {
        userAgent.on('connecting', (event) => {
            logger.log('[userAgent] Connecting:', event);
        });
        userAgent.on('connected', (event) => {
            logger.log('[userAgent] Connected:', event);
            updateConnectionState(userAgent);
        });
        userAgent.on('disconnected', (event) => {
            logger.error('[userAgent] Disconnected:', event);
            showErrorNotification(
                t(
                    '0ebd53df43a45b29e506109a749ba3c7',
                    'Softphone is disconnected'
                )
            );
            updateConnectionState(userAgent);
        });
        userAgent.on('registered', (event) => {
            logger.log('[userAgent] Registered:', event);
            updateConnectionState(userAgent);
        });
        userAgent.on('unregistered', (event) => {
            logger.error('[userAgent] Unregistered:', event);
            showErrorNotification(
                t(
                    '03d35d2d6572f2c83a3ce9c10bcdeee6',
                    'Softphone is unregistered'
                )
            );
            updateConnectionState(userAgent);
        });
        userAgent.on('registrationFailed', (event) => {
            logger.error('[userAgent] Registration failed:', event);
            showErrorNotification(
                t(
                    '678e92c80bcb9f84f3be0794da564116',
                    'Softphone registration failed'
                )
            );
            updateConnectionState(userAgent);
        });
        userAgent.on('newRTCSession', (event) => {
            logger.log('[userAgent] New RTC session:', event);
            if (event?.session?.direction === 'incoming') {
                answerCall(event.session);
            } else if (event?.session?.direction === 'outgoing') {
                setSessionListeners(event.session);
                dispatch({
                    type: SoftphoneActions.AddSession,
                    payload: event.session,
                });
            }
        });
        userAgent.on('newMessage', (event) => {
            logger.log('[userAgent] New message:', event);
        });
        userAgent.on('newOptions', (event) => {
            logger.log('[userAgent] New option:', event);
        });
        userAgent.on('sipEvent', (event) => {
            logger.log('[userAgent] SIP event:', event);
        });
    };

    const setSessionListeners = (session) => {
        session.on('peerconnection', (event) => {
            logger.log('[session] New peer connection:', event);
        });
        session.on('connecting', (event) => {
            logger.log('[session] Connecting session:', event);
        });
        session.on('sending', (event) => {
            logger.log('[session] Sending:', event);
        });
        session.on('progress', (event) => {
            logger.log('[session] Session progress:', event);
        });
        session.on('accepted', (event) => {
            logger.log('[session] Session accepted:', event);
            sessionStarted(session);
        });
        session.on('confirmed', (event) => {
            logger.log('[session] Session confirmed:', event);
            sessionStarted(session);
        });
        session.on('ended', (event) => {
            logger.log('[session] Session ended:', event);
            sessionEnded(session);
        });
        session.on('failed', (event) => {
            logger.error('[session] Session failed:', event);
            showErrorNotification(
                t(
                    '6438a008c5e1139b3823d6031b501fea',
                    'Softphone session failed'
                )
            );
            sessionEnded(session);
        });
        session.on('newDTMF', (event) => {
            if (event.originator === 'local') {
                logger.log('[session] Sent DMTF:', event);
            } else {
                logger.log('[session] Received DMTF:', event);
            }
        });
        session.on('newInfo', (event) => {
            logger.log('[session] New info:', event);
        });
        session.on('hold', (event) => {
            logger.log('[session] Session on hold:', event);
            dispatch({
                type: SoftphoneActions.UpdateSessions,
            });
        });
        session.on('unhold', (event) => {
            logger.log('[session] Session no longer on hold:', event);
            dispatch({
                type: SoftphoneActions.UpdateSessions,
            });
        });
        session.on('muted', (event) => {
            logger.log('[session] Muted:', event);
            dispatch({
                type: SoftphoneActions.UpdateSessions,
            });
        });
        session.on('unmuted', (event) => {
            logger.log('[session] Unmuted:', event);
            dispatch({
                type: SoftphoneActions.UpdateSessions,
            });
        });
        session.on('reinvite', (event) => {
            logger.log('[session] Session reinvite:', event);
        });
        session.on('update', (event) => {
            logger.log('[session] Session update:', event);
        });
        session.on('refer', (event) => {
            logger.log('[session] Session refer:', event);
        });
        session.on('replaces', (event) => {
            logger.log('[session] Session replaces:', event);
        });
        session.on('sdp', (event) => {
            logger.log('[session] Session SDP:', event);
        });
        session.on('icecandidate', (event) => {
            logger.log('[session] New ICE candidate:', event);
        });
        session.on('getusermediafailed', (event) => {
            logger.error('[session] Retrieving user media failed:', event);
            showErrorNotification(
                t(
                    '5c122bcfb4106af9f26217585eeb9504',
                    'Retrieving user media for softphone failed'
                )
            );
        });
        session.on('peerconnection:createofferfailed', (event) => {
            logger.error(
                '[session] Peer connectiong create offer failed:',
                event
            );
            showErrorNotification(
                t(
                    'e45e510b7c7ffcc55dac7afc71de484d',
                    'Softphone peer connection offer failed'
                )
            );
        });
        session.on('peerconnection:createanswerfailed', (event) => {
            logger.error(
                '[session] Peer connectiong create answer failed:',
                event
            );
            showErrorNotification(
                t(
                    '8ef1d1dde6384d8d93ac90f878daf9a6',
                    'Softphone peer connection answer failed'
                )
            );
        });
        session.on('peerconnection:setlocaldescriptionfailed', (event) => {
            logger.error(
                '[session] Peer connectiong set local description failed:',
                event
            );
            showErrorNotification(
                t(
                    '04d6a07eb08d8edf32333025b6b460bb',
                    'Unable to set local description for softphone connection'
                )
            );
        });
        session.on('peerconnection:setremotedescriptionfailed', (event) => {
            logger.error(
                '[session] Peer connectiong set remote description failed:',
                event
            );
            showErrorNotification(
                t(
                    '6bffd39667615586c1237c980517e7c5',
                    'Unable to set remote description for softphone connection'
                )
            );
        });
    };

    const getSession = (id) => {
        return state.sessions.find((session) => session.id === id);
    };

    const sessionStarted = (session) => {
        if (_.isNil(session.connection)) {
            logger.error('[sessionStarted] No connection in session:', session);
            showErrorNotification(
                t(
                    'bbb9faf709d3b31e395e0cf562ea0d5b',
                    'No connection available in softphone session'
                )
            );
            return;
        }

        dispatch({
            type: SoftphoneActions.UpdateSessions,
        });

        let streams = session.connection.getRemoteStreams();
        if (_.isEmpty(streams)) {
            logger.error(
                '[sessionStarted] No streams in session connection:',
                session
            );
            showErrorNotification(
                t(
                    '2585837dffdf7356ced467e05d990e77',
                    'No streams available in softphone session connection'
                )
            );
            return;
        }
        dispatch({
            type: SoftphoneActions.AddRemoteStreams,
            payload: streams,
        });
    };

    const sessionEnded = (session) => {
        dispatch({
            type: SoftphoneActions.RemoveSession,
            payload: session,
        });

        dispatch({
            type: SoftphoneActions.SetDialInput,
            payload: '',
        });
    };

    const updateConnectionState = (userAgent) => {
        let connectionState;
        if (_.isNil(userAgent)) {
            connectionState = SoftphoneConnectionState.NoCredentials;
        } else if (!userAgent.isConnected()) {
            connectionState = SoftphoneConnectionState.Disconnected;
        } else if (!userAgent.isRegistered()) {
            connectionState = SoftphoneConnectionState.Unregistered;
        } else {
            connectionState = SoftphoneConnectionState.Connected;
        }

        dispatch({
            type: SoftphoneActions.SetConnectionState,
            payload: connectionState,
        });
    };

    const showErrorNotification = (content) => {
        showError({
            title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
            ttl: 8000,
            content,
        });
    };

    return (
        <SoftphoneContext.Provider
            value={{
                ...state,
                registerSip,
                holdCall,
                unholdCall,
                sendDTMF,
                toggleMute,
                getActiveSession,
                dialpadInput,
                setDialInput,
                startDialout,
                endDialout,
            }}
        >
            {children}
        </SoftphoneContext.Provider>
    );
});
SoftphoneProvider.displayName = 'SoftphoneProvider';

export const useSoftphone = () => useContext(SoftphoneContext);
export const SoftphoneConsumer = SoftphoneContext.Consumer;
