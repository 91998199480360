import {
    createContext,
    memo,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import { getLogger } from '../utils';
import { useChannels } from '../channels';
import { AlarmsActions, alarmsReducer } from './reducer';
import { useNotifications } from '../notifications';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import {
    getActiveAlarmQueue,
    getAlarmQueue,
    getOperableAlarm,
    postAcceptAlarm,
} from '../uds';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../notifications/context';
import { useAuth } from '../auth';
import { useIdleTimerContext } from 'react-idle-timer';

const logger = getLogger('AlarmsProvider');

const AlarmsContext = createContext({
    eventListeners: [],
    alarmQueue: [],
    alarmQueueCount: 0,
    activeAlarmQueue: [],
    activeAlarmQueueCount: 0,
    getAlarms: () => null,
    addEventListener: () => null,
    removeEventListener: () => null,
    updateSelectedCaregiver: () => null,
});

export const AlarmEvents = {
    NewAlarm: 'new_alarm',
    AlarmAccepted: 'alarm_accepted',
    AlarmOnHold: 'alarm_on_hold',
    AlarmResumed: 'alarm_resumed',
    AlarmDeviceClosed: 'alarm_device_closed',
    AlarmClosed: 'alarm_closed',
};

export const AlarmsProvider = memo(({ children }) => {
    const { t } = useTranslation();
    const idleTimer = useIdleTimerContext();
    const { channel } = useChannels();
    const { showInfo } = useNotifications();
    const { isAuthenticated, isTenantSelected } = useAuth();
    const [state, dispatch] = useReducer(alarmsReducer, {
        eventListeners: [],
        alarmQueue: [],
        alarmQueueCount: 0,
        activeAlarmQueue: [],
        activeAlarmQueueCount: 0,
        operableAlarm: null,
        selectedCaregiver: null,
    });

    useEffect(() => {
        if (channel) {
            logger.log('Set event Channel Listeners');
            const alarmRef = channel.on('alarm', (payload) => {
                logger.log(`channel::alarm::events`, payload);

                state.eventListeners
                    .filter(
                        (listener) =>
                            !_.isUndefined(payload.event) &&
                            listener.eventName === payload.event
                    )
                    .forEach((listener) => {
                        logger.log('Execute listener', listener);
                        if (!_.isFunction(listener.func)) {
                            return;
                        }
                        if (!_.isUndefined(payload.event_data)) {
                            listener.func({
                                event: payload.event,
                                ...payload.event_data,
                            });
                        } else {
                            listener.func(payload);
                        }
                    });
            });
            return () => {
                logger.log('Remove channel event listener');
                channel.off('alarm', alarmRef);
            };
        }
    }, [channel, state]);

    // global new alarm event handler to show app wide notification
    useEffect(() => {
        if (channel) {
            logger.log('Set global alarm Channel Listeners');
            const ref = channel.on('alarm', (payload) => {
                if (payload.event !== 'new_alarm') {
                    return;
                }

                logger.log('channel::new_alarm::global', payload);
                showInfo({
                    type: NotificationType.Desktop,
                    title: t('b235e572f591b0838ae85279842bdd5f', 'New Alarm'),
                    content: `${payload.event_data.data.customer_metadata.criterion_name}: ${payload.event_data.data.customer_metadata.client_name} (${payload.event_data.data.device_code})`,
                    ttl: 0,
                    actionButtonLabel: t(
                        '4abe77c201ff11663ccdf52fd6ecea86',
                        'Accept'
                    ),
                    onActionButtonClick: (removeNotification) => {
                        postAcceptAlarm(payload.event_data.data.alarm_id).then(
                            () => removeNotification()
                        );
                    },
                });
            });
            return () => {
                logger.log("Remove channel event listener on global 'alarm'");
                channel.off('alarm', ref);
            };
        }
    }, [channel, showInfo, t]);

    const addEventListener = useCallback((eventName, func) => {
        logger.log('Add Queue Listener', eventName, func);
        if (!_.isFunction(func)) {
            return;
        }
        const id = uuid();
        dispatch({
            type: AlarmsActions.AddEventListener,
            payload: {
                id,
                eventName,
                func,
            },
        });
        return id;
    }, []);

    const removeEventListener = useCallback((id) => {
        logger.log('Remove Queue Listener', id);
        if (_.isUndefined(id)) {
            return;
        }
        dispatch({
            type: AlarmsActions.RemoveEventListener,
            payload: { id },
        });
    }, []);

    const getAlarms = async () => {
        try {
            const queue = await getAlarmQueue();
            logger.log('Fetched alarm queue', queue);
            dispatch({
                type: AlarmsActions.UpdateAlarmQueue,
                payload: queue,
            });
            return queue;
        } catch (error) {
            logger.warn('Error:', error);
            return 0;
        }
    };

    const getActiveAlarms = async () => {
        try {
            const queue = await getActiveAlarmQueue();
            logger.log('Fetched active alarm queue', queue);
            dispatch({
                type: AlarmsActions.UpdateActiveAlarmQueue,
                payload: queue,
            });
        } catch (error) {
            logger.warn('Error:', error);
        }
    };

    const getCurrentOperableAlarm = useCallback(async () => {
        try {
            const alarm = await getOperableAlarm();
            logger.log('Operable alarm found', alarm);
            idleTimer.pause();
            logger.log('Paused idleTimer');
            dispatch({
                type: AlarmsActions.UpdateOperableAlarm,
                payload: alarm,
            });
        } catch (error) {
            if (error?.response?.status === 404) {
                logger.log('Operable alarm not found');
            } else {
                logger.warn('Error:', error);
            }
            dispatch({
                type: AlarmsActions.UpdateOperableAlarm,
                payload: null,
            });
            idleTimer.resume();
            logger.log('Resumed idleTimer');
        }
    }, [idleTimer]);

    useEffect(() => {
        if (isAuthenticated && isTenantSelected) {
            getAlarms();
            getActiveAlarms();
            getCurrentOperableAlarm();
        } else {
            dispatch({
                type: AlarmsActions.UpdateOperableAlarm,
                payload: null,
            });

            dispatch({
                type: AlarmsActions.UpdateAlarmQueue,
                payload: [],
            });
        }
    }, [isAuthenticated, isTenantSelected, getCurrentOperableAlarm]);

    useEffect(() => {
        const updateQueues = async () => {
            getAlarms();
            getActiveAlarms();
            getCurrentOperableAlarm();
        };

        const alarmNew = (alarm) => {
            logger.log('Alarm new', alarm);
            updateQueues();
        };

        const alarmAccepted = (alarm) => {
            logger.log('Alarm accepted', alarm);
            updateQueues();
        };

        const alarmOnHold = (alarm) => {
            logger.log('Alarm on hold', alarm);
            updateQueues();
        };

        const alarmResumed = (alarm) => {
            logger.log('Alarm resumed', alarm);
            updateQueues();
        };

        const alarmDeviceClosed = (alarm) => {
            logger.log('Alarm device closed', alarm);
            updateQueues();
        };

        const alarmClosed = (alarm) => {
            logger.log('Alarm closed', alarm);
            updateQueues();
        };

        const alarmNewEventId = addEventListener(
            AlarmEvents.NewAlarm,
            alarmNew
        );

        const alarmAcceptedEventId = addEventListener(
            AlarmEvents.AlarmAccepted,
            alarmAccepted
        );
        const alarmOnHoldEventId = addEventListener(
            AlarmEvents.AlarmOnHold,
            alarmOnHold
        );
        const alarmResumedEventId = addEventListener(
            AlarmEvents.AlarmResumed,
            alarmResumed
        );
        const alarmDeviceClosedEventId = addEventListener(
            AlarmEvents.AlarmDeviceClosed,
            alarmDeviceClosed
        );
        const alarmClosedRef = addEventListener(
            AlarmEvents.AlarmClosed,
            alarmClosed
        );

        return () => {
            removeEventListener(alarmNewEventId);
            removeEventListener(alarmAcceptedEventId);
            removeEventListener(alarmOnHoldEventId);
            removeEventListener(alarmResumedEventId);
            removeEventListener(alarmDeviceClosedEventId);
            removeEventListener(alarmClosedRef);
        };
    }, [addEventListener, removeEventListener, getCurrentOperableAlarm]);

    const updateSelectedCaregiver = useCallback((caregiver) => {
        logger.log('Update selected caregiver', caregiver);
        dispatch({
            type: AlarmsActions.UpdateSelectedCaregiver,
            payload: caregiver,
        });
    }, []);

    return (
        <AlarmsContext.Provider
            value={{
                ...state,
                getAlarms,
                addEventListener,
                removeEventListener,
                updateSelectedCaregiver,
            }}
        >
            {children}
        </AlarmsContext.Provider>
    );
});

AlarmsProvider.displayName = 'AlarmProvider';

export const useAlarms = () => useContext(AlarmsContext);
export const AlarmsConsumer = AlarmsContext.Consumer;
