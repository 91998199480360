import { useTranslation } from 'react-i18next';
import './umo-image-view.scss';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

export const UmoImageView = ({
    src,
    noCaching = false,
    maxWidth,
    maxHeight,
    onImageLoadedCallback,
}) => {
    const { t } = useTranslation();
    const imgRef = useRef(null);
    const [loaded, setLoaded] = useState();

    useEffect(() => {
        if (imgRef.current && imgRef.current.complete) {
            setLoaded(true);
        }
    }, [imgRef]);

    useEffect(() => {
        if (!_.isNil(loaded) && _.isFunction(onImageLoadedCallback)) {
            onImageLoadedCallback(loaded);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaded]);

    const getStyle = () => {
        const classes = ['umo-image-view'];

        if (!_.isNil(maxWidth) || !_.isNil(maxHeight)) {
            classes.push('fixed-size');
        }
        return classes.join(' ');
    };

    const getElementStyle = () => {
        if (_.isNil(maxWidth) && _.isNil(maxHeight)) {
            return;
        }

        return {
            ...(maxWidth && {
                maxWidth: maxWidth,
            }),
            ...(maxHeight && {
                maxHeight: maxHeight,
            }),
        };
    };

    if (!src) {
        return t('168f6271829b5ac458c6855b5c77faec', 'Loading failed');
    }

    let imgSrc = src;
    if (noCaching) {
        const delimiter = src.includes('?') ? '&' : '?';
        imgSrc += `${delimiter}t=${new Date().getTime()}`;
    }

    return (
        <img
            ref={imgRef}
            className={getStyle()}
            style={getElementStyle()}
            src={imgSrc}
            onLoad={() => setLoaded(true)}
            onError={() => setLoaded(false)}
        />
    );
};
